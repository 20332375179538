<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card no-body>
          <b-card-body class="remote-cards">
            <div class="remote-img">
              <img src="https://fakeimg.pl/395x180/" alt="">
              <span class="device-title">Copa</span>
              <span class="number-devices">
                <feather-icon
                    class="circle-icon green"
                    icon="CircleIcon"
                />
                4 Equipamentos
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card no-body>
          <b-card-body class="remote-cards">
            <div class="remote-img">
              <img src="https://fakeimg.pl/395x180/" alt="">
              <span class="device-title">Preparação do peixe</span>
              <span class="number-devices">
                <feather-icon
                    class="circle-icon green"
                    icon="CircleIcon"
                />
                2 Equipamentos
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
}
</script>