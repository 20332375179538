<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="remote-listagem">
          <b-card-body>
            <div class="listagem-top">
              <span class="text-listagem">
                  Listagem de equipamentos
              </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-input-group class="input-group-merge search-list">
                    <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input placeholder="Serial number or model" />
                </b-input-group>
                <b-button
                  class="search-btn mr-0"
                  variant="none"
                >
                  <span>GO</span>
                </b-button>
              </div>
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
       searchTerm: '',
    }
  }
}
</script>